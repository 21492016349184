import { ScrollSpy, Collapse } from 'bootstrap'

export function bindScrollSpy(target) {
  return new ScrollSpy(document.body, {
    target: target,
    offset: getOffset()
  })
}

export function stickToTop() {
  _activateSticker()
  _stickToTop()
}

export function initPage() {
  window.addEventListener('scroll', function () {
    if (window.scrollY > 100) {
      document.querySelector('#scroll-to-top').classList.add('show')
    } else {
      document.querySelector('#scroll-to-top').classList.remove('show')
    }
  })

  document.querySelectorAll('a.js-scroll-trigger').forEach(function (elem) {
    elem.addEventListener('click', function (e) {
      // collapse menu
      const cInstance = Collapse.getInstance(document.querySelector('.navbar-collapse'))
      if (cInstance) {
        cInstance.hide()
      }
    })
  })
}

function _stickToTop() {
  const windowTop = window.scrollY
  const top = document.querySelector('#sticker').getBoundingClientRect().top + window.scrollY
  if (windowTop >= top) {
    document.querySelector('.sticky').classList.add('fixed-top')
    document.querySelector('body').classList.add('sticked')
    document.querySelector('#sticker').style.height = document.querySelector('.sticky').style.height
  } else {
    document.querySelector('.sticky').classList.remove('fixed-top')
    document.querySelector('body').classList.remove('sticked')
    document.querySelector('#sticker').style.height = 0
  }
}

function _activateSticker() {
  window.addEventListener('scroll', _stickToTop)
  window.onresize = _stickToTop
}

function getOffset() {
  let offset = 144
  if (document.querySelector('html').clientWidth < 992) {
    offset = 92
  }
  return offset
}
